@import '../../node_modules/@stronghold/bootstrap/scss/bootstrap';

.bg-blue-2 {
    background-color:$blue-2;
}

.cardDetails input {
    font-size: 14px;
    padding: 8px 12px;
    color: rgba(26, 26, 26, 0.9);
    line-height: 1.5;
    border: 0;
    box-shadow: 0 0 0 1px #e0e0e0;
    margin-bottom: 1px;
    width: 250px;
}

.tsep-cardHolderName input {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.tsep-cardHolderName input:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .7),
    0 0 0 4px rgba(50, 151, 211, .3);
    outline: none;
}

.tsep-cardNum input {
    border-radius: 0px;
}

.tsep-cardNum input:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .7),
    0 0 0 4px rgba(50, 151, 211, .3);
    outline: none;
}

.tsep-datePicker input {
    border-radius: 0px;
}

.tsep-datePicker input:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .7),
    0 0 0 4px rgba(50, 151, 211, .3);
    outline: none;
}

.tsep-cvv2 input {
    border-radius: 0px;
}

.tsep-cvv2 input:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .7),
    0 0 0 4px rgba(50, 151, 211, .3);
    outline: none;
}

.tsep-zip input {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.tsep-zip input:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, .7),
    0 0 0 4px rgba(50, 151, 211, .3);
    outline: none;
}